/* global FB */
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify'
import { useGoogleLogin } from '@react-oauth/google';

import { onLoginByGoogle } from '../redux/slices/LoginSlice';
import { setPurchaseNotice } from '../redux/slices/PurchaseNoticeSlice';

import LoginForm from '../components/forms/LoginForm'
import LoginHeader from '../components/login/LoginHeader'

import Button from '../UI/Button/Button';
import GoogleBtn from '../UI/googleBtn/GoogleBtn.tsx';
import { Paragraph, TextError } from '../UI/Typography/Typography';
import FbBtn from '../UI/fbBtn/FbBtn';


import cl from '../styles/components/forms/LoginForm.module.css'
import { loginRequest } from '../msalConfig';
import { useMsal } from '@azure/msal-react';
import MsBtn from '../UI/msBtn/MsBtn';
import axios from 'axios';
import Spinner from '../UI/spinner/Spinner';
import { Toast } from 'primereact/toast';

function Login() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const location = useLocation();

	function getQueryParam(param) {
		return new URLSearchParams(location.search).get(param);
	}

	const selectedModuls = getQueryParam('selectedmoduls');
	const quantityModuls = getQueryParam('quantitymoduls');
	const videoModule = getQueryParam('videomodule');

	const [loginWithoutGoogleError, setLoginWithoutGoogleError] = useState('')

	const sendGoogleToken = async (response) => {
		const res = await dispatch(onLoginByGoogle(response.access_token))
		if (res.payload.status === 200 || res.payload.status === 'success') {
			localStorage.setItem('token', res.payload.token)
			localStorage.setItem('email', res.payload.email)
			localStorage.setItem('id', res.payload.id)

			if (selectedModuls && quantityModuls) {
				dispatch(setPurchaseNotice({ comID: res.payload.companyId, type: 'flex', date: new Date(), quantity: quantityModuls, modules: selectedModuls, period: 'Monthly', showed: 0, videos: videoModule || false }))
			}

			if (res.payload.type === 'login') {
				navigate('/home/vr')
			} else {
				navigate('/home/vr?hello=true')
			}
		} else {
			toast.error('Failed to login. Try again later')
		}
	}

	const googleLogin = useGoogleLogin({
		onSuccess: async tokenResponse => {
			await sendGoogleToken(tokenResponse)
		},
		onError: (err) => {
			toast.error('Failed to login')
		},
	});

	const [fbLoaded, setFbLoaded] = useState(false);

	const loadFacebookSDK = () => {
		// Если скрипт уже загружен, не загружаем повторно
		if (fbLoaded) {
			return Promise.resolve();
		}

		return new Promise((resolve, reject) => {
			// Создаем глобальный коллбэк, который сработает после инициализации SDK
			window.fbAsyncInit = function () {
				FB.init({
					appId: '1630968434123066', // Замените на свой App ID
					cookie: true,
					xfbml: true,
					version: 'v21.0'
				});
				setFbLoaded(true);
				resolve();
			};

			// Загружаем скрипт Facebook SDK
			const script = document.createElement('script');
			script.src = "https://connect.facebook.net/en_US/sdk.js";
			script.async = true;
			script.defer = true;
			script.onerror = reject;
			document.body.appendChild(script);
		});
	};

	const [socAuthLoading, setSocAuthLoading] = useState(false)

	const fbLogin = async () => {
		// Сначала загружаем и инициализируем SDK
		await loadFacebookSDK();

		// Когда SDK готов, вызываем окно логина
		FB.login((response) => {
			if (response.status === 'connected') {
				// Получаем данные о пользователе
				FB.api('/me', { fields: 'name, email' }, (userInfo) => {
					console.log('Добро пожаловать, ' + userInfo.name);
					console.log('Ваш email: ' + userInfo.email);
					console.log('userInfo');
					const userData = {
						name: userInfo.name || '',
						email: userInfo.email,
					};

					// ТУТ ПРИМЕР ДЛЯ ДОБАВЛЕНИЯ ЗАПРОСА АВТОРИЗАЦИИИ ПОСЛЕ ПОЛУЧЕНИЯ ИНФЫ ОТ МС
					axios.post('https://devapitest.metaenga.com/auth/facebook', userData).then((data) => {
						console.log(data, 'data');
						localStorage.setItem('token', data.data.token)
						localStorage.setItem('email', data.data.email)
						localStorage.setItem('id', data.data.id)
						navigate('/home/vr')
					});


					//if (selectedModuls && quantityModuls) {
					//	dispatch(setPurchaseNotice({ comID: res.payload.companyId, type: 'flex', date: new Date(), quantity: quantityModuls, modules: selectedModuls, period: 'Monthly', showed: 0, videos: videoModule || false }))
					//}



				});
			} else {
				console.log('Пользователь отменил вход или возникла ошибка при авторизации.');
			}
		}, { scope: 'public_profile,email' });
	};


	const { instance, accounts } = useMsal();

	const microsoftLogin = async () => {
		setSocAuthLoading(true)
		try {
			const response = await instance.loginPopup(loginRequest);
			const { account, accessToken } = response;

			console.log(response, 'response MCS')

			const userData = {
				name: account.name || '',
				email: account.username,
				token: accessToken, // Отправляем токен для проверки на сервере
			};

			// ТУТ ПРИМЕР ДЛЯ ДОБАВЛЕНИЯ ЗАПРОСА АВТОРИЗАЦИИИ ПОСЛЕ ПОЛУЧЕНИЯ ИНФЫ ОТ МС
			const serverResponse = await axios.post('https://devapitest.metaenga.com/auth/microsoft', userData);
			console.log('Ответ сервера:', serverResponse);

			localStorage.setItem('token', serverResponse.data.token)
			localStorage.setItem('email', serverResponse.data.email)
			localStorage.setItem('id', serverResponse.data.id)

			//if (selectedModuls && quantityModuls) {
			//	dispatch(setPurchaseNotice({ comID: res.payload.companyId, type: 'flex', date: new Date(), quantity: quantityModuls, modules: selectedModuls, period: 'Monthly', showed: 0, videos: videoModule || false }))
			//}

			navigate('/home/vr')


		} catch (error) {
			console.error('Ошибка при логине:', error);
		}
		setSocAuthLoading(false)
	};


	return (
		<LoginHeader header='Log in'>
			{loginWithoutGoogleError && <TextError style={{ marginTop: 8 }}>{loginWithoutGoogleError}</TextError>}
			<LoginForm setBigError={setLoginWithoutGoogleError} />
			<GoogleBtn onClick={googleLogin} />
			<FbBtn onClick={fbLogin} />
			<MsBtn onClick={microsoftLogin} />
			{socAuthLoading && <Spinner />}

			<div className={cl.signin}>
				<Paragraph>Log in with</Paragraph>
				<Button linkbtn onClick={() => navigate('/login-with-code')} >Verification Code</Button>
			</div>
			<div className={cl.signup}>
				<Paragraph>Don't have an account?</Paragraph>
				<Button linkbtn onClick={() => navigate('/self-registration')} >Sign up</Button>
			</div>
			<ToastContainer
				theme='colored'
				position="bottom-right"
				closeButton={false}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		</LoginHeader>
	)
}

export default Login