import React from 'react';
import classNames from 'classnames';
import cl from './ScrollBlock.module.css';
import { ScrollPanel } from 'primereact/scrollpanel';

interface ScrollBlockProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode;
	height?: number | string;
	maxHeight?: number | null;
	scroll: boolean;
}

const ScrollBlock: React.FC<ScrollBlockProps> = ({ children, height = 50, maxHeight = null, scroll = true, ...props }) => {
	return (
		scroll ?
			<ScrollPanel
				{...props}
				className={classNames('main__scrollbar', props.className)}
				style={{ height,  width: '100%', ...props.style }}
			>
				{children}
			</ScrollPanel>
			: <div       {...props}
				className={classNames(props.className)}
				style={{ ...props.style }}>
				{children}
			</div>
	);
	//return (
	//	scroll ?
	//		<div
	//			{...props}
	//			className={classNames(cl.scroll, props.className)}
	//			style={{ height, maxHeight: maxHeight ?? undefined, ...props.style }}
	//		>
	//			{children}
	//		</div>
	//		: <div       {...props}
	//			className={classNames(props.className)}
	//			style={{ ...props.style }}>
	//			{children}
	//		</div>
	//);
};

export default ScrollBlock;
