import { useState, useEffect } from 'react';

function usePageHeight() {
	const [pageHeight, setPageHeight] = useState(document.documentElement.scrollHeight);

	useEffect(() => {
		const handleResize = () => {
			setPageHeight(document.documentElement.scrollHeight);
		};

		handleResize(); // Установить начальную высоту

		const observer = new ResizeObserver(() => {
			handleResize();
		});

		observer.observe(document.documentElement);

		window.addEventListener('resize', handleResize);

		return () => {
			observer.disconnect();
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return pageHeight;
}

export default usePageHeight;