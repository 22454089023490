import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TABLET_WIDTH, useWindowWidth } from '../hooks/useWindowWidth';

import BackButton from '../UI/backButton/BackButton';
import HeaderPage from '../UI/headerPage/HeaderPage';
import { Header, Paragraph, TextError } from '../UI/Typography/Typography';
import Input from '../UI/input/Input';
import Button from '../UI/Button/Button';

import cl from './ContactSales.module.css';

import imgRequest from '../assets/icons/request.svg'

function ContactSales() {
	const path = window.location.pathname;
	const winWidth = useWindowWidth()
	const navigate = useNavigate()

	const { email, name } = useSelector((state) => state.userInfo.info)
	const { companyName } = useSelector((state) => state.company.info)
	const [formInputs, setFormInputs] = useState({
		email,
		userName: name || '',
		company: companyName || '',
		message: ''
	})
	const [error, setError] = useState({
		email: '',
		userName: '',
		company: '',
		message: '',
		sent: ''
	})
	const [loading, setLoading] = useState(false)
	const [sent, setSent] = useState(false)

	useEffect(() => {
		if(email) {
			setFormInputs(prev => ({ ...prev, email, userName: name }));
		}
		if(companyName) {
			setFormInputs(prev => ({ ...prev, company: companyName }));
		}
	}, [email, companyName])


	const title = path.includes('/home/contact-sales') ? <HeaderPage>Contact sales</HeaderPage> : <BackButton title='Subscription' />

	const titleMob = path.includes('/home/contact-sales') ? <BackButton title='Home' /> : <BackButton title='Subscription' />

	const onSetInputValue = (e) => {
		const { name, value } = e.target;
		setFormInputs(prev => ({ ...prev, [name]: value }));
		validateField(name, value);
	}

	const onScheduleMeet = () => {
		navigate('book-a-demo', { replace: true })
	}

	const validateField = (fieldName, value) => {
		let errorMsg = '';

		switch (fieldName) {
			case 'userName':
				if (!value.trim()) {
					errorMsg = 'Required';
				}
				break;
			case 'company':
				if (!value.trim()) {
					errorMsg = 'Required';
				}
				break;
			case 'email':
				const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				if (!emailRegex.test(value)) {
					errorMsg = 'Email not valid';
				}
				break;
			case 'message':
				// Если сообщение обязательно, раскомментируйте следующие строки:
				/*
				if (!value.trim()) {
					errorMsg = 'Пожалуйста, введите сообщение';
				}
				*/
				break;
			default:
				break;
		}

		// Обновляем состояние ошибок для конкретного поля
		setError(prev => ({ ...prev, sent: '', [fieldName]: errorMsg }));
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true)
		try {
			let html = `
			<h1>Contact sales (app)</h1> </br></br>
			<p><b>Full name: </b> ${formInputs.userName}</p></br>
			<p><b>Email: </b> ${formInputs.email}</p></br>
			<p><b>Company: </b> ${formInputs.company}</p></br>
			<p><b>Message: </b> ${formInputs.message}</p></br>
			`;

			await fetch("https://platform.metaenga.com/contactSales/mail/send", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					mail: formInputs.email,
					fullName: formInputs.userName,
					company: formInputs.company,
					html,
					subject: "Contact sales (app)"
				})
			})
			setLoading(false)
			setSent(true)
		} catch (e) {
			setError(prev => ({ ...prev, sent: 'Message not sent. Try again later' }))
			setLoading(false)
		}
	}

	return (
		<div className="page__container">
			{winWidth < TABLET_WIDTH
				? titleMob
				: title}
			{sent ?
				<div className={cl.success__sent}>
					<img src={imgRequest} alt='Smile icon' />
					<Header>Success!</Header>
					<Paragraph>Thank you for reaching out. We will get back to you shortly</Paragraph>
					<Paragraph>Meanwhile, schedule a meeting with our team <br />
						for a detailed discussion.</Paragraph>
					<div className={cl.buttons}>
						<Button onClick={onScheduleMeet}>Schedule a meeting</Button>
					</div>
				</div> :

				<div className={cl.sales}>
					<Header>Contact sales</Header>
					<Paragraph>Fill out this quick form and <span style={{whiteSpace: 'nowrap'}}>we’ll be in touch shortly</span></Paragraph>
					<form className={cl.form}>
						<div className={cl.input}>
							<Input
								disabled={loading}
								value={formInputs.userName}
								onChange={onSetInputValue}
								name='userName'
								label='Full name*'
								err={error.userName}
								helperText={error.userName} />
						</div>
						<div className={cl.input}>
							<Input
								disabled={true}
								value={formInputs.email}
								name='email'
								label='Work email*'
								err={error.email}
								helperText={error.email}
							/>
						</div>
						<div className={cl.input}>
							<Input
								disabled={loading}
								value={formInputs.company}
								onChange={onSetInputValue}
								label='Company*'
								name='company'
								err={error.company}
								helperText={error.company} />
						</div>
						<div className={cl.input}>
							<Input
								disabled={loading}
								value={formInputs.message}
								onChange={onSetInputValue}
								type='area'
								name='message'
								label='Message'
								rows={4}
								style={{ resize: 'none' }}
							/>
						</div>
						{error.sent && <TextError style={{ textAlign: 'center' }}>{error.sent}</TextError>}
						<div className={cl.buttons}>
							<Button type='button' onClick={onScheduleMeet} secondary>Schedule a meeting</Button>
							<Button type='submit' disabled={error.company || error.email || error.message || error.sent || error.userName || !formInputs.company || !formInputs.userName || loading} onClick={onSubmit} loading={loading}>Submit {winWidth > 540 && 'form'}</Button>
						</div>
					</form>
				</div>}
		</div>
	)
}

export { ContactSales }