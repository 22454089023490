import React, { useEffect, useState } from 'react'
import ContentModal from '../../../UI/modal/ContentModal.tsx'
import Modal from '../../../UI/modal/Modal'
import { Paragraph } from '../../../UI/Typography/Typography'
import { freePlanName } from '../../../variables/planNames'

function Modals({ ids, actions, onClose, onConfirm, teamName, deviceName, ...props }) {
	const [modalOpen, setModalOpen] = useState(false)
	const [selectModal, setSelectModal] = useState({})

	useEffect(() => {
		if (actions === 'deactivate'
			|| actions === 'delete'
			|| actions === 'reactivate'
			|| actions === 'revoke'
			|| actions === 'resend'
			|| actions === 'deleteTeam'
			|| actions === 'removeFromTeam'
			|| actions === 'kiosk'
			|| actions === 'kioskOff'
			|| actions === 'vrLogout'
			|| actions === 'revokeAccess'
			|| actions === 'deleteDevice'
			|| actions === 'deleteTraining'
			|| actions === 'removeAccess'
			|| actions === 'removeAccessVR'
			|| actions === 'removeAccessFromGroup'
			|| actions === 'deleteRoom'
			|| actions === 'leaveCR'
			|| actions === 'oneVideoDelete'
			|| actions === 'deleteMultipleVideos'
			|| actions === 'cancelSubscr'
			|| actions === 'deleteDevices'
		) {
			const modalContent = [
				{
					action: 'deactivate',
					header: 'Deactivate user',
					text: "Deactivated users won’t be able to sign in to the account and won’t recive notifications. They can be restored or deleted in the Deactivated tab",
					btnText: 'Deactivate',
				},
				{
					action: 'delete',
					header: 'Delete users',
					text: 'If you delete users then you will lose their data',
					btnText: 'Delete',
				},
				{
					action: 'reactivate',
					header: 'Reactivate user',
					text: `Reactivate ${ids?.length} user${ids?.length > 1 ? "s" : ''}? You can find it in the Active tab`,
					btnText: 'Reactivate',
				},
				{
					action: 'revoke',
					header: 'Revoke access',
					text: `Revoke access to ${ids?.length} user${ids?.length > 1 ? "s" : ''}?`,
					btnText: 'Revoke',
				},
				{
					action: 'resend',
					header: 'Resend invitation',
					text: `Resend invitation to ${ids?.length} user${ids?.length > 1 ? "s" : ''}?`,
					btnText: 'Resend',
				},
				{
					action: 'deleteTeam',
					header: 'Delete teams',
					text: `Delete ${ids?.length} team${ids?.length > 1 ? "s" : ''}?`,
					btnText: 'Delete',
				},
				{
					action: 'removeFromTeam',
					header: 'Remove from team',
					text: `Remove ${ids?.length} user${ids?.length > 1 ? "s" : ''} from ${teamName}?`,
					btnText: 'Remove',
				},
				{
					action: 'kiosk',
					header: 'Launch in kiosk mode',
					text: `Training will be launched in kiosk mode`,
					btnText: 'Launch',
				},
				{
					action: 'kioskOff',
					header: 'Turn off kiosk mode',
					text: `Kiosk mode will be turned off`,
					btnText: 'Turn off',
				},
				{
					action: 'vrLogout',
					header: 'Headset Log out',
					text: `Are you sure you want to terminate the session on the device?`,
					btnText: 'Log out',
				},
				{
					action: 'revokeAccess',
					header: 'Revoke access',
					text: `Are you sure you want to revoke access?`,
					btnText: 'Revoke',
				},
				{
					action: 'deleteDevice',
					header: 'Delete device',
					text: <>Do you want to delete {deviceName} from the list? <br /> This action will free up 1 license.</>,
					btnText: 'Delete',
				},
				{
					action: 'deleteDevices',
					header: 'Delete devices',
					text: <>Do you want to delete {ids?.length} device{ids?.length > 1 ? "s" : ''} from the list? <br /> This action will free up {ids?.length} license{ids?.length > 1 ? "s" : ''}.</>,
					btnText: 'Delete',
				},
				{
					action: 'deleteTraining',
					header: 'Delete training',
					text: `Delete training from ${deviceName}?`,
					btnText: 'Delete',
				},
				{
					action: 'removeAccess',
					header: 'Delete access',
					text: `Are you sure you want to delete access to this training?`,
					btnText: 'Delete',
				},
				{
					action: 'removeAccessVR',
					header: 'Delete access',
					text: `Are you sure you want to delete access to this training?`,
					btnText: 'Delete',
				},
				{
					action: 'removeAccessFromGroup',
					header: 'Remove lessons',
					text: `Remove ${ids?.length} lessons from team?`,
					btnText: 'Remove',
				},
				{
					action: 'deleteRoom',
					header: 'Delete classroom',
					text: `Are you sure you want to delete ${ids?.length} classroom${ids?.length > 1 ? "s" : ''}?`,
					btnText: 'Delete',
				},
				{
					action: 'leaveCR',
					header: 'End session',
					text: `If you leave this page, the session will be stopped and all users will be disconnected.`,
					btnText: 'End session',
				},
				{
					action: 'oneVideoDelete',
					header: 'Video delete',
					text: `Are you sure you want to delete this video?`,
					btnText: 'Delete',
				},
				{
					action: 'deleteMultipleVideos',
					header: 'Videos delete',
					text: `You can only delete client videos. Delete?`,
					btnText: 'Delete',
				},
				{
					action: 'cancelSubscr',
					header: 'Cancel Standard subscription',
					text: `Your plan will downgrade to the ${freePlanName} of the Metaenga at the end of your current subscription period. All future payments will be canceled.`,
					btnText: 'Cancel Subscription',
					closeBtn: 'Close'
				},
			]
			setSelectModal(modalContent.find(el => el.action === actions))
			setModalOpen(true)
		}
	}, [actions])

	return (
		<Modal style={{ maxWidth: '90%', width: 440 }} modalIsOpen={modalOpen} setIsOpen={setModalOpen} callback={onClose}>
			<ContentModal setModalIsOpen={setModalOpen} header={selectModal.header} onConfirm={onConfirm} onClose={onClose} textButton={selectModal.btnText} closeButton={selectModal.closeBtn || 'Cancel'}>
				<Paragraph style={{ textAlign: 'center', marginBottom: 28, lineHeight: '130%', color: 'var(--textdark-color)' }}>{selectModal.text}</Paragraph>
			</ContentModal>
		</Modal>
	)

}

export default Modals