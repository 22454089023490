import React from 'react';
import Button from '../Button/Button';

interface MsBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string; 
}

const MsBtn: React.FC<MsBtnProps> = ({ text = 'Continue with Microsoft', ...props }) => {
  return (
    <Button  {...props} style={{margin: 20}}>
      <div >
        {/*<img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="google logo" />*/}
      </div>
      <span>{text}</span>
    </Button>
  );
};

export default MsBtn;