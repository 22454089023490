import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";

import { SubscriptionService } from "../../services/SubscriptionServices";

export const getSubscriptionInfo = createAsyncThunk(
	'subscription/getSubscriptionInfo',
	async (userId, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await SubscriptionService.getSubscriptionInfo(token, userId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPaymentToken = createAsyncThunk(
	'subscription/getPaymentToken',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await SubscriptionService.getPaymentToken(token, data);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPaymentTokenFlex = createAsyncThunk(
	'subscription/getPaymentTokenFlex',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, headsets, items, video, amount } = data;
		try {
			const response = await SubscriptionService.getPaymentTokenFlex(token, userId, headsets, items, video, amount);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const cancelSubscription = createAsyncThunk(
	'subscription/cancelSubscription',
	async (userId, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await SubscriptionService.cancelSubscription(token, userId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const cancelFlexSubscription = createAsyncThunk(
	'subscription/cancelFlexSubscription',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { comID, moduleId } = data;
		try {
			const response = await SubscriptionService.cancelFlexSubscription(token, comID, moduleId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const cancelFlexSubscription360 = createAsyncThunk(
	'subscription/cancelFlexSubscription360',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await SubscriptionService.cancelFlexSubscription360(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)



export const getSubscriptionHistory = createAsyncThunk(
	'subscription/getSubscriptionHistory',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await SubscriptionService.getSubscriptionHistory(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const sendBillingInfo = createAsyncThunk(
	'subscription/sendBillingInfo',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { comID, ammount } = data;
		try {
			const response = await SubscriptionService.sendBillingInfo(token, comID, ammount);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const createPdfInvoice = createAsyncThunk(
	'subscription/createPdfInvoice',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { companyName, billingAddress, contactName, email, companyId, typeSubscription, total, unitPrice, numberLicenses } = data
		try {
			const response = await SubscriptionService.createPdfInvoice(token, companyName, billingAddress, contactName, email, companyId, typeSubscription, total, unitPrice, numberLicenses);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getInvoicePdf = createAsyncThunk(
	'subscription/getInvoicePdf',
	async (invoiceId, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await SubscriptionService.getInvoicePdf(token, invoiceId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getRecieptPdf = createAsyncThunk(
	'subscription/getRecieptPdf',
	async (orderId, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await SubscriptionService.getRecieptPdf(token, orderId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPaidModuls = createAsyncThunk(
	'subscription/getPaidModuls',
	async (userId, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await SubscriptionService.getPaidModuls(token, userId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPaymentReceipt = createAsyncThunk(
	'subscription/onGetPaymentReceipt',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await SubscriptionService.getPaymentReceipt(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const sendUpdateFlex = createAsyncThunk(
	'subscription/sendUpdateFlex',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { products, userId } = data
		try {
			const response = await SubscriptionService.sendUpdateFlexPlan(token, products, userId);
			return response;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const sendUpdateFlexModuleDecrease = createAsyncThunk(
	'subscription/sendUpdateFlexModule',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { items, userId } = data
		try {
			const response = await SubscriptionService.sendUpdateFlexModuleDecrease(token, items, userId);
			return response;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const sendUpdateFlexModuleIncrease = createAsyncThunk(
	'subscription/sendUpdateFlexModuleIncrease',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { items, userId } = data
		try {
			const response = await SubscriptionService.sendUpdateFlexModuleIncrease(token, items, userId);
			return response;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const sendUpdateFlexSubscr = createAsyncThunk(
	'subscription/sendUpdateFlexSubscr',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { items, userId, video } = data
		try {
			const response = await SubscriptionService.sendUpdateFlexSubscr(token, items, userId, video);
			return response;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

const subscriptionAdapter = createEntityAdapter({
	selectId: (subscription) => uid(8),
});

const subscriptionSlice = createSlice({
	name: "subscription",
	initialState: subscriptionAdapter.getInitialState({
		info: null,
		history: null,
		loading: false,
		paidModuls: null,
		quantityPaidModuls: null,
		paidModalsLoading: false,
		updateModuleLoading: false,
		errorUpdateModule: null,
		updateFlexSubscrLoading: false,
		errorUpdateFlexSubscr: null,
	}),
	extraReducers: (builder) => {
		builder
			.addCase(getSubscriptionInfo.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getSubscriptionInfo.fulfilled, (state, action) => {
				state.loading = false;
				let licensePush = +action.payload.data.license.split("/")[1]
				let lisenseAssoc = +action.payload.data.license.split("/")[0]
				let card = action.payload.data.masked_card?.slice(-4) || null;
				let info
				info = {
					...action.payload.data,
					subscriptionType: action.payload.data.subscriptionType,
					licensePush,
					lisenseAssoc,
					card,
					subscriptionSummary: action.payload.data.subscriptionSummary
				}
				//if (action.payload.data.invoice === 1) {
				//	info = {
				//		...action.payload.data,
				//		subscriptionType: action.payload.data.subscriptionType,
				//		licensePush,
				//		lisenseAssoc,
				//		card,
				//		subscriptionSummary: 100,
				//		subscriptionEnd: "2024-12-31",
				//		nextPayment: "2024-12-31",
				//		subscriptionStatus: 'paid',

				//	}
				//} else {
				//	info = {
				//		...action.payload.data,
				//		subscriptionType: action.payload.data.subscriptionType,
				//		licensePush,
				//		lisenseAssoc,
				//		card,
				//		subscriptionSummary: action.payload.data.subscriptionSummary
				//	}
				//}
				state.info = info;
			})
			.addCase(getSubscriptionInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPaymentToken.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getPaymentToken.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(getPaymentToken.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPaymentTokenFlex.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getPaymentTokenFlex.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(getPaymentTokenFlex.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(cancelSubscription.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(cancelSubscription.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(cancelSubscription.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getSubscriptionHistory.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getSubscriptionHistory.fulfilled, (state, action) => {
				state.loading = false;
				//let newHistory = action.payload.data.filter(item => +item.payment_amount > 1)
				//	.sort((a, b) => new Date(a.payment_date) - new Date(b.payment_date)).reverse();
				//state.history = newHistory;

				let newHistory = action.payload.data.sort((a, b) => new Date(a.payment_date) - new Date(b.payment_date)).reverse();
				state.history = newHistory
			})
			.addCase(getSubscriptionHistory.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(sendBillingInfo.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(sendBillingInfo.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(sendBillingInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(createPdfInvoice.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(createPdfInvoice.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(createPdfInvoice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getInvoicePdf.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getInvoicePdf.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(getInvoicePdf.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getRecieptPdf.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getRecieptPdf.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(getRecieptPdf.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPaymentReceipt.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getPaymentReceipt.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(getPaymentReceipt.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPaidModuls.pending, (state, action) => {
				state.paidModalsLoading = true;
			})
			.addCase(getPaidModuls.fulfilled, (state, action) => {
				state.loading = false;
				let prodQuantity = 0
				const products = action.payload.products.map(item => {
					if (item.metadata.id) {
						prodQuantity += 1;
					}

					return {
						id: item.metadata.id || 'video',
						name: item.name,
						price: item.quantity * 30 || 10,
						quantity: item.quantity,
						description: item.metadata.id ? `Purchased licenses: ${item.quantity}` : `Storage used: ${state.info.uploadLimit}`,
						url: item.images[0] || '',
						used: item.used
					}
				}).sort((a, b) => a.id.localeCompare(b.id));
				state.paidModuls = products;
				state.paidModalsLoading = false;
				state.quantityPaidModuls = prodQuantity;
			})
			.addCase(getPaidModuls.rejected, (state, action) => {
				state.paidModuls = null;
				state.paidModalsLoading = false;
				state.error = action.payload;
			})

			.addCase(sendUpdateFlex.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(sendUpdateFlex.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(sendUpdateFlex.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(cancelFlexSubscription.pending, (state, action) => {
				state.loading = false;
			})
			.addCase(cancelFlexSubscription.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(cancelFlexSubscription.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(cancelFlexSubscription360.pending, (state, action) => {
				state.loading = false;
			})
			.addCase(cancelFlexSubscription360.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(cancelFlexSubscription360.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(sendUpdateFlexModuleDecrease.pending, (state, action) => {
				state.updateModuleLoading = true;
				state.errorUpdateModule = null;
			})
			.addCase(sendUpdateFlexModuleDecrease.fulfilled, (state, action) => {
				state.updateModuleLoading = false;
			})
			.addCase(sendUpdateFlexModuleDecrease.rejected, (state, action) => {
				state.updateModuleLoading = false;
				state.errorUpdateModule = action.payload;
			})

			.addCase(sendUpdateFlexModuleIncrease.pending, (state, action) => {
				state.updateModuleLoading = true;
				state.errorUpdateModule = null;
			})
			.addCase(sendUpdateFlexModuleIncrease.fulfilled, (state, action) => {
				state.updateModuleLoading = false;
			})
			.addCase(sendUpdateFlexModuleIncrease.rejected, (state, action) => {
				state.updateModuleLoading = false;
				state.errorUpdateModule = action.payload;
			})

			.addCase(sendUpdateFlexSubscr.pending, (state, action) => {
				state.updateFlexSubscrLoading = true;
				state.errorUpdateFlexSubscr = null;
			})
			.addCase(sendUpdateFlexSubscr.fulfilled, (state, action) => {
				state.updateFlexSubscrLoading = false;
			})
			.addCase(sendUpdateFlexSubscr.rejected, (state, action) => {
				state.updateFlexSubscrLoading = false;
				state.errorUpdateFlexSubscr = action.payload;
			})
	}
});

export default subscriptionSlice.reducer;