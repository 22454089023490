import { useEffect, useState, lazy, Suspense } from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate
} from "react-router-dom";
import ReactGA from "react-ga4";
import { toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { PrimeReactProvider } from "primereact/api";

import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener';
import { TABLET_WIDTH, useWindowWidth } from './hooks/useWindowWidth';

import ChangePassword from './pages/ChangePassword';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';

import PaymentReceipt from './UI/paymentRecipt/PaymentReceipt';
import UsersPage from './pages/team/UsersPage';
import TeamsPage from './pages/team/TeamsPage';

import { Paragraph } from './UI/Typography/Typography';
import SpinnerBlock from './UI/spinner/SpinnerBlock';
import { routesConfig } from './routesConfig';
import VRSessionsListByUserPage from './pages/VRSessionsListByUserPage';
import { ContactSales } from './pages/ContactSales';

const VRLibrary = lazy(() => import('./pages/VRLibraryPage'));
const Test = lazy(() => import('./Test'));
const CompanyProfile = lazy(() => import('./pages/settings/CompanyProfile'));
const Account = lazy(() => import('./pages/settings/Account'));
const Team = lazy(() => import('./pages/Team'));
const TeamSettings = lazy(() => import('./pages/team/teams/TeamSettings'));
const ErrorPage = lazy(() => import('./pages/errorPages/ErrorPage'));
const Insights = lazy(() => import('./pages/Insights'));
const VideoInfo = lazy(() => import('./pages/VideoInfo'));
const ClassroomContainer = lazy(() => import('./pages/ClassroomContainer'));
const SelfRegistration = lazy(() => import('./pages/SelfRegistration'));
const SubscriptionPage = lazy(() => import('./pages/settings/SubscriptionPage'));
const PersonalInsights = lazy(() => import('./pages/PersonalInsights'));
const InsightsMob = lazy(() => import('./pages/InsightsMob'));
const VRSessionsListPage = lazy(() => import('./pages/VRSessionsListPage'));
const PersonalVRSessionsListPage = lazy(() => import('./pages/PersonalVRSessionsListPage'));
const VRSessionDetails = lazy(() => import('./pages/vrSessionsList/VRSessionDetails'));
const ServerOffline = lazy(() => import('./pages/errorPages/ServerOffline'));
const LoginWithCode = lazy(() => import('./pages/LoginWithCode'));
const VerificationSuccess = lazy(() => import('./pages/VerificationSuccess'));
const TestTwo = lazy(() => import('./TestTwo'));
const BuildFlex = lazy(() => import('./pages/settings/subscribtionPage/BuildFlex'));
const RedirectWithParams = lazy(() => import('./utils/RedirectWithParams'));
const SubscrPayment = lazy(() => import('./pages/settings/subscribtionPage/payment/SubscrPayment'));
const SubscriptionUpdate = lazy(() => import('./pages/settings/subscribtionPage/update/SubscriptionUpdate'));
const BookDemo = lazy(() => import('./pages/settings/subscribtionPage/BookDemo'));
const UserDeactivated = lazy(() => import('./pages/UserDeactivated'));
const UpdateFlex = lazy(() => import('./pages/settings/subscribtionPage/editPlan/UpdateFlex'));
const SubscrProductInfo = lazy(() => import('./pages/settings/subscribtionPage/editPlan/SubscrProductInfo'));
const UpdateFlexModule = lazy(() => import('./pages/settings/subscribtionPage/editPlan/UpdateFlexModule'));
const UpdateFlexModuleDecrease = lazy(() => import('./pages/settings/subscribtionPage/editPlan/UpdateFlexModuleDecrease'));
const Classrooms = lazy(() => import('./pages/Classrooms'));
const VideosLibrary = lazy(() => import('./pages/VideosLibraryPage'));
const TrainingInfo = lazy(() => import('./pages/TrainingInfo'));
const Settings = lazy(() => import('./pages/Settings'));
const DevicesPage = lazy(() => import('./pages/team/DevicesPage'));
const Device = lazy(() => import('./pages/team/devices/Device'));



function App() {
	const winWidth = useWindowWidth();
	//* SW update listener
	const [updateWaiting, setUpdateWaiting] = useState(false);
	const [registration, setRegistration] = useState(null);
	const [swListener, setSwListener] = useState({});
	const hostname = window.location.hostname;

	const clearIrrelevantGaCookies = (id) => {
		const targetContainerId = id; // Идентификатор, который нужно сохранить
		const cookies = document.cookie.split("; ");
		cookies.forEach(cookie => {
			const [name, value] = cookie.split("=");

			// Удаляем только те куки, которые начинаются с '_ga_' и не соответствуют нужному контейнеру
			if (name.startsWith('_ga_') && !name.includes(targetContainerId)) {
				document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
			}
		});
	};

	useEffect(() => {
		if (window.location.hostname === 'app.metaenga.com') {
			clearIrrelevantGaCookies('JVN6BTTCGL')

			const gtmScript = document.createElement('script');
			gtmScript.src = "https://www.googletagmanager.com/gtm.js?id=GTM-W3P96D5T"; // Replace 'GTM-XXXXXXX' with your GTM container ID.
			gtmScript.async = true;
			document.head.appendChild(gtmScript);

			gtmScript.onload = () => {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'gtm.start': new Date().getTime(),
					event: 'gtm.js'
				});
			};

			return () => {
				document.head.removeChild(gtmScript);
			};
		}
	}, [])


	useEffect(() => {
		if (process.env.NODE_ENV !== "development") {
			let listener = new ServiceWorkerUpdateListener();
			setSwListener(listener);
			listener.onupdateinstalling = (installingEvent) => {
				;
			};
			listener.onupdatewaiting = (waitingEvent) => {
				;
				setUpdateWaiting(true);
			};
			listener.onupdateready = (event) => {
				;
				window.location.reload();
			};
			navigator.serviceWorker.getRegistration().then((reg) => {
				listener.addRegistration(reg);
				setRegistration(reg);
			});
			return () => listener?.removeEventListener();
		}
	}, [])

	const skipWaitingUpdate = () => {
		swListener.skipWaiting(registration.waiting);
	}
	useEffect(() => {
		if (updateWaiting) {
			//localStorage.removeItem('id');
			//localStorage.removeItem('token');
			//localStorage.removeItem('email');
			skipWaitingUpdate()
		}
	}, [updateWaiting])

	const update = <div style={{ display: 'flex', alignItems: 'center' }}>
		<Paragraph style={{ marginRight: 5 }}>
			New version of the app is available
		</Paragraph>
		<Paragraph
			style={{ fontWeight: 500, color: 'var(--primary-color)' }}
			onClick={skipWaitingUpdate}
		>Update</Paragraph>
	</div>

	//const renderRoutes = (routes) =>
	//	routes.map(({ path, element, children }) => {
	//		return (
	//			<Route key={path + Math.random()} path={path} element={<Suspense fallback={<SpinnerBlock height={300} />}>{element}</Suspense>}>
	//				{children && renderRoutes(children)}
	//			</Route>
	//		);
	//	});

	return (
		<>
			<GoogleOAuthProvider clientId="1000504771834-vv3s8rd5df3nk3a35ua2j4ma0i3gdv2g.apps.googleusercontent.com">
				<PrimeReactProvider >
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Login />} />
							<Route path="/login-with-code" element={<Suspense fallback={<SpinnerBlock height={300} />}><LoginWithCode /></Suspense>} />
							<Route path="/registration" element={<Signup />} />
							<Route path="/verification-success" element={<Suspense fallback={<SpinnerBlock height={300} />}><VerificationSuccess /></Suspense>} />
							<Route path="/user-deactivated" element={<Suspense fallback={<SpinnerBlock height={300} />}><UserDeactivated /></Suspense>} />
							<Route path="/user-deleted" element={<Suspense fallback={<SpinnerBlock height={300} />}><UserDeactivated unit='company' /></Suspense>} />
							<Route path="/self-registration" element={<Suspense fallback={<SpinnerBlock height={300} />}><SelfRegistration /></Suspense>} />
							<Route path="/changepassword" element={<Suspense fallback={<SpinnerBlock height={300} />}><ChangePassword /></Suspense>} />
							<Route path="/home" element={<Home />}>
								<Route index element={<Navigate to="/home/vr" replace />} />
								<Route path='vr' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRLibrary /></Suspense>} />

								<Route path='videos' element={<Suspense fallback={<SpinnerBlock height={300} />}><VideosLibrary /></Suspense>} />

								<Route path='settings/:accountId' element={<Suspense fallback={<SpinnerBlock height={300} />}><Account /></Suspense>} />
								<Route path='settings/:accountId/sessions' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionsListByUserPage /></Suspense>} />
								<Route path='settings/:accountId/sessions/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionDetails /></Suspense>} />

								<Route path='settings/company' element={<Suspense fallback={<SpinnerBlock height={300} />}><CompanyProfile /></Suspense>} />

								<Route path='insights/:trainingId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionsListPage /></Suspense>} />
								<Route path='insights/:trainingId/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionDetails /></Suspense>} />
								<Route path='insights/:trainingId/user-:accountId' element={<Suspense fallback={<SpinnerBlock height={300} />}><Account /></Suspense>} />
								<Route path='insights/:trainingId/user-:accountId/sessions' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionsListByUserPage /></Suspense>} />
								<Route path='insights/:trainingId/user-:accountId/sessions/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionDetails /></Suspense>} />

								<Route path='vr/:id' element={<Suspense fallback={<SpinnerBlock height={300} />}><TrainingInfo /></Suspense>} />

								<Route path='videos/:id' element={<Suspense fallback={<SpinnerBlock height={300} />}><VideoInfo /></Suspense>} />
								<Route path='classrooms' element={<Suspense fallback={<SpinnerBlock height={300} />}><Classrooms /></Suspense>} />
								<Route path='classrooms/:id' element={<Suspense fallback={<SpinnerBlock height={300} />}><ClassroomContainer /></Suspense>} />

								{winWidth > TABLET_WIDTH && (
									<>
										<Route path='contact-sales' element={<Suspense fallback={<SpinnerBlock height={300} />}><ContactSales /></Suspense>} />

										<Route path='vr/:id/training-sessions-:trainingId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionsListPage /></Suspense>} />
										<Route path='vr/:id/training-sessions-:trainingId/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionDetails /></Suspense>} />
										<Route path='vr/:id/training-sessions-:trainingId/user-:accountId' element={<Suspense fallback={<SpinnerBlock height={300} />}><Account /></Suspense>} />
										<Route path='vr/:id/training-sessions-:trainingId/user-:accountId/sessions' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionsListByUserPage /></Suspense>} />
										<Route path='vr/:id/training-sessions-:trainingId/user-:accountId/sessions/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionDetails /></Suspense>} />

										<Route path='settings' element={<Navigate to="/home/vr" replace />} />
										<Route path='team/users' element={<Suspense fallback={<SpinnerBlock height={300} />}><UsersPage /></Suspense>} />
										<Route path='team/users' element={<Navigate to="/home/team/teams" replace />} />

										<Route path='team/users/user-:accountId' element={<Suspense fallback={<SpinnerBlock height={300} />}><Account /></Suspense>} />
										<Route path='team/users/user-:accountId/sessions' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionsListByUserPage /></Suspense>} />
										<Route path='team/users/user-:accountId/sessions/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionDetails /></Suspense>} />

										<Route path='team/teams' element={<Suspense fallback={<SpinnerBlock height={300} />}><TeamsPage /></Suspense>} />
										<Route path='settings/team' element={<Navigate to="/home/team/users" replace />} />
										<Route path='settings/team/users' element={<Navigate to="/home/team/users" replace />} />
										<Route path='settings/team/teams' element={<Navigate to="/home/team/teams" replace />} />

										<Route path='team/teams/:teamName' element={<Suspense fallback={<SpinnerBlock height={300} />}><TeamSettings /></Suspense>} />
										<Route path='settings/team/teams/:teamName' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName="teamName" to="home/team/teams/:teamName" /></Suspense>} />

										<Route path='team/teams/:teamName/user-:accountId' element={<Suspense fallback={<SpinnerBlock height={300} />}><Account /></Suspense>} />
										<Route path='team/teams/:teamName/user-:accountId/sessions' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionsListByUserPage /></Suspense>} />
										<Route path='team/teams/:teamName/user-:accountId/sessions/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionDetails /></Suspense>} />

										<Route path='team/devices' element={<Suspense fallback={<SpinnerBlock height={300} />}><DevicesPage /></Suspense>} />
										<Route path='settings/team/devices' element={<Navigate to="/home/team/devices" replace />} />

										<Route path='team/devices/:id' element={<Suspense fallback={<SpinnerBlock height={300} />}><Device /></Suspense>} />
										<Route path='settings/team/devices/:id' element={<Navigate to="/home/team/devices" replace />} />

										<Route path='insights' element={<Suspense fallback={<SpinnerBlock height={300} />}><Insights /></Suspense>} />
										<Route path='settings/insights' element={<Navigate to="/home/insights" replace />} />
										<Route path='settings/insights/company' element={<Navigate to="/home/insights" replace />} />



										<Route path='my-insights' element={<Suspense fallback={<SpinnerBlock height={300} />}><PersonalInsights /></Suspense>} />
										<Route path='settings/insights/personal' element={<Navigate to="/home/my-insights" replace />} />

										<Route path='my-insights/:trainingId' element={<Suspense fallback={<SpinnerBlock height={300} />}><PersonalVRSessionsListPage /></Suspense>} />
										<Route path='settings/insights/personal/:trainingId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName="trainingId" to="home/my-insights/:trainingId" replace /></Suspense>} />



										<Route path='my-insights/:trainingId/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionDetails /></Suspense>} />
										<Route path='settings/insights/personal/:trainingId/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName={["trainingId", "sessionId"]} to="home/my-insights/:trainingId/:sessionId" replace /></Suspense>} />



										<Route path='subscription' element={<Suspense fallback={<SpinnerBlock height={300} />}><SubscriptionPage /></Suspense>} />
										<Route path='settings/subscription' element={<Navigate to="/home/subscription" replace />} />

										<Route path='subscription/payment' element={<Suspense fallback={<SpinnerBlock height={300} />}><SubscrPayment /></Suspense>} />
										<Route path='settings/subscription/payment' element={<Navigate to="/home/subscription/payment" replace />} />


										<Route path='subscription/flex' element={<Suspense fallback={<SpinnerBlock height={300} />}><BuildFlex /></Suspense>} />
										<Route path='settings/subscription/flex' element={<Navigate to="/home/subscription/flex" replace />} />

										<Route path='subscription/update' element={<Suspense fallback={<SpinnerBlock height={300} />}><SubscriptionUpdate /></Suspense>} />
										<Route path='settings/subscription/update' element={<Navigate to="/home/subscription/update" replace />} />

										<Route path='subscription/book-a-demo' element={<Suspense fallback={<SpinnerBlock height={300} />}><BookDemo /></Suspense>} />
										<Route path='settings/subscription/book-a-demo' element={<Navigate to="/home/subscription/book-a-demo" replace />} />

										<Route path='subscription/contact-sales' element={<Suspense fallback={<SpinnerBlock height={300} />}><ContactSales /></Suspense>} />
										<Route path='settings/subscription/contact-sales' element={<Navigate to="/home/subscription/contact-sales" replace />} />


										<Route path='subscription/contact-sales/book-a-demo' element={<Suspense fallback={<SpinnerBlock height={300} />}><BookDemo /></Suspense>} />
										<Route path='settings/subscription/contact-sales/book-a-demo' element={<Navigate to="/home/subscription/contact-sales/book-a-demo" replace />} />

										<Route path='subscription/update-flex' element={<Suspense fallback={<SpinnerBlock height={300} />}><UpdateFlex /></Suspense>} />
										<Route path='settings/subscription/update-flex' element={<Navigate to="/home/subscription/update-flex" replace />} />


										<Route path='subscription/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><SubscrProductInfo /></Suspense>} />
										<Route path='settings/subscription/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName="moduleId" to="home/subscription/:moduleId" replace /></Suspense>} />

										<Route path='subscription/update-flex/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><UpdateFlexModule /></Suspense>} />
										<Route path='settings/subscription/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName="moduleId" to="home/subscription/update-flex/:moduleId" replace /></Suspense>} />


										<Route path='subscription/remove-licenses/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><UpdateFlexModuleDecrease /></Suspense>} />
										<Route path='settings/subscription/remove-licenses/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName="moduleId" to="home/subscription/remove-licenses/:moduleId" replace /></Suspense>} />

									</>
								)}
								{winWidth < TABLET_WIDTH && (
									<>
										<Route path='team/users' element={<Navigate to="/home/settings/team/users" replace />} />

										<Route path='settings' element={<Suspense fallback={<SpinnerBlock height={300} />}><Settings /></Suspense>} />
										<Route path='settings/team' element={<Suspense fallback={<SpinnerBlock height={300} />}><Team /></Suspense>} />

										<Route path='settings/team/teams' element={<Suspense fallback={<SpinnerBlock height={300} />}><TeamsPage /></Suspense>} />
										<Route path='team/teams' element={<Navigate to="/home/settings/team/teams" replace />} />

										<Route path='settings/team/users' element={<Suspense fallback={<SpinnerBlock height={300} />}><UsersPage /></Suspense>} />

										<Route path='settings/team/users/user-:accountId' element={<Suspense fallback={<SpinnerBlock height={300} />}><Account /></Suspense>} />
										<Route path='settings/team/users/user-:accountId/sessions' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionsListByUserPage /></Suspense>} />

										<Route path='settings/team/teams/:teamName' element={<Suspense fallback={<SpinnerBlock height={300} />}><TeamSettings /></Suspense>} />
										<Route path='settings/team/teams/:teamName/user-:accountId' element={<Suspense fallback={<SpinnerBlock height={300} />}><Account /></Suspense>} />
										<Route path='settings/team/teams/:teamName/user-:accountId/sessions' element={<Suspense fallback={<SpinnerBlock height={300} />}><VRSessionsListByUserPage /></Suspense>} />
										<Route path='team/teams/:teamName' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName="teamName" to="home/settings/team/teams/:teamName" /> </Suspense>} />

										<Route path='settings/team/devices' element={<Suspense fallback={<SpinnerBlock height={300} />}><DevicesPage /></Suspense>} />
										<Route path='team/devices' element={<Navigate to="/home/settings/team/devices" replace />} />

										<Route path='settings/team/devices/:id' element={<Suspense fallback={<SpinnerBlock height={300} />}><Device /></Suspense>} />
										<Route path='team/devices/:id' element={<Navigate to="/home/settings/team/devices" replace />} />

										<Route path='settings/insights' element={<Suspense fallback={<SpinnerBlock height={300} />}><InsightsMob /></Suspense>} />
										<Route path='insights' element={<Navigate to="/home/settings/insights/company" replace />} />

										<Route path='settings/insights/company' element={<Suspense fallback={<SpinnerBlock height={300} />}><Insights /></Suspense>} />

										<Route path='settings/insights/personal' element={<Suspense fallback={<SpinnerBlock height={300} />}><PersonalInsights /></Suspense>} />
										<Route path='my-insights' element={<Navigate to="/home/settings/insights/personal" replace />} />

										<Route path='settings/insights/personal/:trainingId' element={<Suspense fallback={<SpinnerBlock height={300} />}><PersonalVRSessionsListPage /></Suspense>} />
										<Route path='my-insights/:trainingId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName={['trainingId']} to="home/settings/insights/personal/:trainingId" replace /> </Suspense>} />

										<Route path='settings/insights/personal/:trainingId/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><PersonalVRSessionsListPage /></Suspense>} />
										<Route path='my-insights/:trainingId/:sessionId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName={['sessionId', 'trainingId']} to="home/settings/insights/personal/:trainingId/:sessionId" replace /></Suspense>} />


										<Route path='settings/subscription' element={<Suspense fallback={<SpinnerBlock height={300} />}><SubscriptionPage /></Suspense>} />
										<Route path='subscription' element={<Navigate to="/home/settings/subscription" replace />} />

										<Route path='settings/subscription/payment' element={<Suspense fallback={<SpinnerBlock height={300} />}><SubscrPayment /></Suspense>} />
										<Route path='subscription/payment' element={<Navigate to="/home/settings/subscription/payment" replace />} />

										<Route path='settings/subscription/flex' element={<Suspense fallback={<SpinnerBlock height={300} />}><BuildFlex /></Suspense>} />
										<Route path='subscription/flex' element={<Navigate to="/home/settings/subscription/flex" replace />} />

										<Route path='settings/subscription/update' element={<Suspense fallback={<SpinnerBlock height={300} />}><SubscriptionUpdate /></Suspense>} />
										<Route path='subscription/update' element={<Navigate to="/home/settings/subscription/update" replace />} />

										<Route path='settings/subscription/book-a-demo' element={<Suspense fallback={<SpinnerBlock height={300} />}><BookDemo /></Suspense>} />
										<Route path='subscription/book-a-demo' element={<Navigate to="/home/settings/subscription/book-a-demo" replace />} />

										<Route path='settings/subscription/contact-sales' element={<Suspense fallback={<SpinnerBlock height={300} />}><ContactSales /></Suspense>} />
										<Route path='subscription/contact-sales' element={<Navigate to="/home/settings/subscription/contact-sales" replace />} />

										<Route path='settings/subscription/contact-sales/book-a-demo' element={<Suspense fallback={<SpinnerBlock height={300} />}><BookDemo /></Suspense>} />
										<Route path='subscription/contact-sales/book-a-demo' element={<Navigate to="/home/settings/subscription/contact-sales/book-a-demo" replace />} />

										<Route path='settings/subscription/update-flex' element={<Suspense fallback={<SpinnerBlock height={300} />}><UpdateFlex /></Suspense>} />
										<Route path='subscription/update-flex' element={<Navigate to="/home/settings/subscription/update-flex" replace />} />

										<Route path='settings/subscription/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><SubscrProductInfo /></Suspense>} />
										<Route path='subscription/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName="moduleId" to="home/settings/subscription/:moduleId" replace /> </Suspense>} />

										<Route path='settings/subscription/update-flex/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><UpdateFlexModule /></Suspense>} />
										<Route path='subscription/update-flex/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName="moduleId" to="home/settings/subscription/update-flex/:moduleId" replace /></Suspense>} />

										<Route path='settings/subscription/remove-licenses/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><UpdateFlexModuleDecrease /></Suspense>} />
										<Route path='subscription/remove-licenses/:moduleId' element={<Suspense fallback={<SpinnerBlock height={300} />}><RedirectWithParams paramName="moduleId" to="home/settings/subscription/remove-licenses/:moduleId" replace /></Suspense>} />
									</>
								)}


								<Route path='test' element={<Suspense fallback={<SpinnerBlock height={300} />}><Test /></Suspense>} />
								<Route path='test2' element={<Suspense fallback={<SpinnerBlock height={300} />}><TestTwo /></Suspense>} />

							</Route>
							<Route path="/payment-receipt" element={<Suspense fallback={<SpinnerBlock height={300} />}><PaymentReceipt /></Suspense>} />
							<Route path="502" element={
								<Suspense fallback={<SpinnerBlock height={300} />}><ServerOffline /></Suspense>
							} />
							<Route path="*" element={
								<Suspense fallback={<SpinnerBlock height={300} />}><ErrorPage /></Suspense>
							} />

						</Routes>
					</BrowserRouter>

				</PrimeReactProvider>
			</GoogleOAuthProvider>
		</>
	);
}

export default App;
